<template>
  <v-container fill-height>   
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="8"
        md="4"
        lg="4"
        xl="3"
      >
        <SignupCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SignupCard } from '@components/Auth'

export default {
  components: {
    SignupCard
  }
}
</script>